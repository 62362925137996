import { getFormProps, useForm } from '@conform-to/react'
import { getZodConstraint, parseWithZod } from '@conform-to/zod'
import { invariant } from '@epic-web/invariant'
import {
	json,
	redirect,
	type LoaderFunctionArgs,
	type ActionFunctionArgs,
	type MetaFunction,
} from '@remix-run/node'
import { Form, useActionData, useLoaderData } from '@remix-run/react'
import { GeneralErrorBoundary } from '#app/components/error-boundary.tsx'
import { requireAnonymous, resetUserPassword } from '#app/utils/auth.server.ts'
import { prisma } from '#app/utils/db.server.ts'
import { useIsPending } from '#app/utils/misc.tsx'
import { PasswordAndConfirmPasswordSchema } from '#app/utils/user-validation.ts'
import { verifySessionStorage } from '#app/utils/verification.server.ts'
import { Button } from '../../components/ui/radixUiTheme/Button.tsx'
import { Input } from '../../components/ui/radixUiTheme/Input'
import { APP_NAME } from '../../constants'
import { OuterForm } from './_components/_outerForm.tsx'
import { type VerifyFunctionArgs } from './verify.tsx'

const resetPasswordUserEmailSessionKey = 'resetPasswordUserEmail'
const resetPasswordUserFirstNameSessionKey = 'resetPasswordUserFirstName'
const resetPasswordUserLastNameSessionKey = 'resetPasswordUserLastName'

const ResetPasswordSchema = PasswordAndConfirmPasswordSchema

export async function handleVerification({ submission }: VerifyFunctionArgs) {
	invariant(
		submission.status === 'success',
		'Submission should be successful by now',
	)
	const target = submission.value.target
	const user = await prisma.user.findFirst({
		where: { email: { email: target } },
		select: { email: true, firstName: true, lastName: true },
	})
	// we don't want to say the user is not found if the email is not found
	// because that would allow an attacker to check if an email is registered
	if (!user) {
		return json(
			{
				result: submission.reply({ fieldErrors: { code: ['Invalid code'] } }),
			},
			{
				status: 400,
			},
		)
	}

	const verifySession = await verifySessionStorage.getSession()
	verifySession.set(resetPasswordUserEmailSessionKey, user.email)
	verifySession.set(resetPasswordUserFirstNameSessionKey, user.firstName)
	verifySession.set(resetPasswordUserLastNameSessionKey, user.lastName)

	return redirect('/reset-password', {
		headers: {
			'set-cookie': await verifySessionStorage.commitSession(verifySession),
		},
	})
}

async function requireResetPasswordUserEmail(request: Request) {
	await requireAnonymous(request)
	const verifySession = await verifySessionStorage.getSession(
		request.headers.get('cookie'),
	)
	const resetPasswordUserEmail = verifySession.get(
		resetPasswordUserEmailSessionKey,
	)
	const resetPasswordUserFirstName = verifySession.get(
		resetPasswordUserFirstNameSessionKey,
	)
	const resetPasswordUserLastName = verifySession.get(
		resetPasswordUserLastNameSessionKey,
	)

	if (typeof resetPasswordUserEmail !== 'string' || !resetPasswordUserEmail) {
		throw redirect('/login')
	}

	return {
		resetPasswordUserEmail,
		resetPasswordUserFirstName,
		resetPasswordUserLastName,
	}
}

export async function loader({ request }: LoaderFunctionArgs) {
	const resetPasswordUserData = await requireResetPasswordUserEmail(request)
	return json({
		resetPasswordUserData,
	})
}

export async function action({ request }: ActionFunctionArgs) {
	const resetPasswordUserData = await requireResetPasswordUserEmail(request)
	const formData = await request.formData()

	const submission = parseWithZod(formData, {
		schema: ResetPasswordSchema,
	})
	if (submission.status !== 'success') {
		return json(
			{ result: submission.reply() },
			{ status: submission.status === 'error' ? 400 : 200 },
		)
	}
	const { password } = submission.value

	await resetUserPassword({
		email: resetPasswordUserData.resetPasswordUserEmail,
		password,
	})
	const verifySession = await verifySessionStorage.getSession()
	return redirect('/login', {
		headers: {
			'set-cookie': await verifySessionStorage.destroySession(verifySession),
		},
	})
}

export const meta: MetaFunction = () => {
	return [{ title: `Reset Password | ${APP_NAME}` }]
}

export default function ResetPasswordPage() {
	const data = useLoaderData<typeof loader>()
	const actionData = useActionData<typeof action>()
	const isPending = useIsPending()

	const [form, fields] = useForm({
		id: 'reset-password',
		constraint: getZodConstraint(ResetPasswordSchema),
		lastResult: actionData?.result,
		onValidate({ formData }) {
			return parseWithZod(formData, { schema: ResetPasswordSchema })
		},
		shouldRevalidate: 'onBlur',
	})

	const fullName = `${data.resetPasswordUserData.resetPasswordUserFirstName} ${data.resetPasswordUserData.resetPasswordUserLastName}`

	return (
		<OuterForm
			headingChildren="Password Reset"
			subHeadingChildren={`Hi, ${fullName}. No worries. It happens all the time.`}
			formChildren={
				<Form method="POST" {...getFormProps(form)}>
					<Input
						type="password"
						name="password"
						labelText="New Password"
						outerClassName="w-full"
						error={fields.password.errors?.[0]}
						isErrorless={false}
						autoComplete="new-password"
						autoFocus={true}
					/>
					<Input
						type="password"
						name="confirmPassword"
						labelText="Confirm Password"
						outerClassName="w-full"
						error={fields.confirmPassword.errors?.[0]}
						isErrorless={false}
						autoComplete="confirm-password"
					/>

					<Button className="w-full" type="submit" disabled={isPending}>
						Reset password
					</Button>
				</Form>
			}
		/>
	)
}

export function ErrorBoundary() {
	return <GeneralErrorBoundary />
}
